import './index';
import $, { ajax } from 'jquery';
import 'jquery-validation';
import('jquery-validation/dist/localization/messages_de.min.js')


function getParents(node) {
    var nodes = [node]
    for (; node; node = node.parentNode) {
      nodes.unshift(node)
    }
    return nodes
}

$('.newsletter__form').each(function(index, form) {
    $(form).validate({
        rules: {
            "newsletter-email":{
                required: true,
                email: true
            },
            "newsletter-rgpd": {
                required: true
            }
        },
        errorPlacement: function(error, element) {
            var $insertAfter = element.closest('.js-validate-insertafter, .form-aside-checkbox, .form-aside-radio');
            if($insertAfter.length){
                error.insertAfter($insertAfter);
            } else {
                error.insertAfter(element);
            }
        },
        submitHandler: function () {
            let ajaxOptions = {
                url: tb_scripts_params.ajax_url.replace(/ \\ g /, ' '),
                type: 'POST',
                success: function (response) {
                    response = JSON.parse(response);
                    if (response.response.replace(/[\r\n\t]/g, "") == 'ok') {
                        $('.newsletter__form').find('.newsletterForm-error').attr('aria-hidden', 'true').addClass('is-hidden');
                        $('.newsletter__form').find('.newsletterForm-fields').attr('aria-hidden', 'true').addClass('is-hidden');
                        $('.newsletter__form').find('.newsletter__form_row').attr('aria-hidden', 'true').addClass('is-hidden');
                        if(response.is_update){
                            $('.newsletter__form').find('.newsletterForm-exists').attr('aria-hidden', 'false').removeClass('is-hidden');
                        }
                        if(response.is_pending){
                            $('.newsletter__form').find('.newsletterForm-confirm').attr('aria-hidden', 'false').removeClass('is-hidden');
                        }
    
                        window.dataLayer = window.dataLayer || [];
                        window.dataLayer.push({
                            'event': 'GAEvent',
                            'eventCategory': 'Formulaires',
                            'eventAction': 'Submit',
                            'eventLabel': document.title
                        });
                    } else {
                        if(response.captcha == 'ko') {
                            const label = document.createElement('label')
                            label.id = 'error-captcha'
                            label.classList.add('error')
                            label.textContent = "Dieses Feld ist ein Pflichtfeld."
                            $(form).find('.ia-block-captcha').append(label)
                        }                       
                        $('.newsletter__form').find('.newsletterForm-error').attr('aria-hidden', 'false').removeClass('is-hidden');
                    }
                },
                error: function (xhr, response, errorThrown) {
                    $('.newsletter__form').find('.newsletterForm-error').attr('aria-hidden', 'false').removeClass('is-hidden');
                }
            }
            ajaxOptions.data = $(form).serialize()
            
            $.ajax(ajaxOptions);
        }
    });
})


if (!!document.querySelector('.captcha-option')) {
    document.querySelectorAll('.captcha-option input').forEach(captchaOption => {
        captchaOption.addEventListener('click', (evt) => {
            let that = evt.currentTarget
            let target = document.getElementById(evt.currentTarget.getAttribute('data-target'))

            if (that.checked) {
                target.value = that.value
            } else {
                target.value = ''
            }
            target.dispatchEvent(new Event('change'))
        })
    })
}


document.querySelectorAll('.ia-block-liveticker__list_posts__item__content').forEach(liveticker => {
    liveticker.querySelectorAll('.abh_box').forEach(authorBoxes => {
        authorBoxes.remove()
    })
})


document.querySelectorAll('.header__push__link').forEach(pushLink=>{
    pushLink.addEventListener('click', function(e){
        e.preventDefault();
        document.querySelector('#pushengage-subscription-management-widget-bell').click();
    })
})

// Paywall
document.querySelectorAll('.ia-paywall').forEach(paywall => {
    var children = document.querySelector('.post__body .text-content').children
    var tempChildren = []
    Array.from(children).forEach((child, index) => {
        if(index > 4) {
            tempChildren.push(child)
            child.remove()
        }else{
            // remove child if has specific alignement to avoid bad layout
            if(child.classList.contains('shortcode_align-left')||child.classList.contains('shortcode_align-right')){
                tempChildren.push(child)
                child.remove()
            }
        }
    })
    document.querySelector('.post__body .text-content').append(paywall)
});


// Live ticker
function getLiveTicker(lt){
    const id = lt.dataset.id
    let formData = new URLSearchParams({
        action: 'iaGetLiveTicker',
        postID: id
    });

    const request  = fetch(
    tb_scripts_params.ajax_url.replace(/ \\ g /, ' '),
    {
        method: "POST",
        body: formData,
        headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        }
    }
    ).then((res) => res.json()).then(liveTicker => {
        lt.innerHTML = liveTicker;
        
        const scripts = lt.querySelectorAll('.is-provider-twitter script');
        scripts.forEach(script => {
            $.getScript(script.getAttribute('src'));
        })
    })
}
if(!!document.querySelector('.ia-block-liveticker')){
    document.querySelectorAll('.ia-block-liveticker').forEach(liveticker => {
        getLiveTicker(liveticker);
        const interval = setInterval(function(){
            getLiveTicker(liveticker);
        },60000);
    });
    /*
    getLiveTicker();
    const interval = setInterval(function(){
        getLiveTicker()
    }, 60000);
    */
}
document.querySelectorAll('.btn-map').forEach(btnIframeMap => {
    btnIframeMap.addEventListener('click', (e)=>{
        const idMap = btnIframeMap.dataset.target;
        document.querySelectorAll('.btn-map').forEach(btnIframe=>{
            btnIframe.classList.remove('active');
        })
        document.querySelectorAll('.ia-iframe-block-category .collapse').forEach(iframe=>{
            iframe.classList.remove('active');
        })
        btnIframeMap.classList.add('active');
        document.getElementById(idMap.split('#')[1]).classList.add('active');
    })
})

document.querySelectorAll('.widget__imu').forEach(imuAds => {
    let time = 0;
    let imuAdsInterval = setInterval(function(){
        if(!!imuAds.querySelector('iframe')) {
            imuAds.classList.remove('remove-height');
            clearInterval(imuAdsInterval)
        }else if(time == 20000){
            clearInterval(imuAdsInterval)
        }
        else{
            imuAds.parentElement.classList.add('remove-height');
            time = time + 50 
        }
    },100)
})
document.querySelectorAll('.is-sticky').forEach(stickyAds => {
    let time = 0;
    let stickyAdsInterval = setInterval(function(){
        if(!!stickyAds.querySelector('iframe')) {
            stickyAds.classList.remove('remove-height');
            clearInterval(stickyAdsInterval)

            if(!!document.querySelector('body:not(.home)')){
                let parentArray = getParents(document.querySelector('.is-sticky')) 
                let elementHeights = {};
                parentArray.forEach(stickyParent => {
                    if(stickyParent.classList != undefined){
                        if(stickyParent.classList.contains('layout__sidebar')){
                            elementHeights.sideBarHeight = stickyParent.offsetHeight
                        }
                        if(stickyParent.classList.contains('sidebar')){
                            elementHeights.sideBarContentHeight = stickyParent.offsetHeight
                        }
                    }
                }) 
                const diffHeight = elementHeights.sideBarHeight - elementHeights.sideBarContentHeight;
                if(elementHeights.sideBarHeight > elementHeights.sideBarContentHeight) {
                    document.querySelector('.is-sticky').style.height = elementHeights.sideBarHeight - elementHeights.sideBarContentHeight - 200 + 'px';
                }
            }


        }else if(time == 20000){
            clearInterval(stickyAdsInterval)
        }
        else{
            stickyAds.classList.add('remove-height');
            time = time + 50 
        }
    },100)
})
document.querySelectorAll('.ads-has-border-bottom').forEach(myBanner => {
    let time = 0;
    let myBannerInterval = setInterval(function(){
        if(!!myBanner.querySelector('iframe')) {
            myBanner.classList.remove('remove-height');
            clearInterval(myBannerInterval)

            if(!!document.querySelector('body:not(.home)')){
                let parentArray = getParents(document.querySelector('.ads-has-border-bottom')) 
                let elementHeights = {};
                parentArray.forEach(stickyParent => {
                    if(stickyParent.classList != undefined){
                        if(stickyParent.classList.contains('layout__sidebar')){
                            elementHeights.sideBarHeight = stickyParent.offsetHeight
                        }
                        if(stickyParent.classList.contains('sidebar')){
                            elementHeights.sideBarContentHeight = stickyParent.offsetHeight
                        }
                    }
                }) 
                const diffHeight = elementHeights.sideBarHeight - elementHeights.sideBarContentHeight;
                if(elementHeights.sideBarHeight > elementHeights.sideBarContentHeight) {
                    document.querySelector('.ads-has-border-bottom').style.height = elementHeights.sideBarHeight - elementHeights.sideBarContentHeight - 200 + 'px';
                }
            }


        }else if(time == 20000){
            clearInterval(myBannerInterval)
        }
        else{
            myBanner.classList.add('remove-height');
            time = time + 50 
        }
    },100)
})

document.querySelectorAll('.gallery').forEach(galery => {
    var maxHeight = 0;
    setTimeout(function(){

        galery.querySelectorAll('.gallery__main__caption').forEach(galeryCaption => {
            console.log(galeryCaption.clientHeight)
            if (galeryCaption.clientHeight > maxHeight){
                maxHeight = galeryCaption.clientHeight
            }
        })
        //setHeight(galery, maxHeight)
    }, 300)
})

function setHeight(galery, height) {
    console.log(height)

    galery.querySelectorAll('.gallery__main__caption').forEach(galeryCaption => {
        galeryCaption.style.height = height + 'px'
    })
}