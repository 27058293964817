(() => {
    var o = [, (e, t, o) => {
            o.r(t)
        }, () => {
            window.addEventListener("DOMContentLoaded", () => {
                if (!(!!window.MSInputMethodContext && !!document.documentMode)) {
                    var e = {
                        root: null,
                        rootMargin: "0px",
                        threshold: .1
                    };
                    document.documentElement.classList.add("reveal-loaded");
                    const t = new IntersectionObserver((e, t) => {
                            e.forEach(e => {
                                .1 < e.intersectionRatio && (e.target.classList.add("reveal-visible"), t.unobserve(e.target))
                            })
                        }, e),
                        o = document.querySelectorAll(".reveal");
                    o.forEach(e => {
                        t.observe(e)
                    })
                }
            })
        }, () => {
            document.addEventListener("DOMContentLoaded", () => {
                const t = document.querySelector("body"),
                    o = "isScrolledToTop",
                    r = "isScrolledToBottom",
                    n = "isScrolled";
                let a = 0;
                0 !== window.scrollY && t.classList.add(n), window.addEventListener("scroll", () => {
                    var e = window.scrollY;
                    0 !== e && t.classList.add(n), e > a && (t.classList.add(r), t.classList.remove(o)), e < a && (t.classList.add(o), t.classList.remove(r)), 0 === e && (t.classList.remove(n), t.classList.remove(o)), a = e
                })
            })
        }, () => {
            document.addEventListener("DOMContentLoaded", () => {
                const t = () => {
                        const e = window.getComputedStyle(document.querySelector("body"));
                        return e.content.replaceAll('"', "")
                    },
                    o = () => {
                        var e = .01 * window.viewport.height;
                        document.documentElement.style.setProperty("--vh", e + "px")
                    };
                window.viewport = {
                    width: window.innerWidth,
                    height: window.innerHeight,
                    device: t(),
                    is(e) {
                        const t = ["xs", "sm", "md", "lg"];
                        var o = e.match(/xs|sm|md|lg/),
                            r = t.indexOf(o[0]),
                            o = e.match(/[<|>]=?/);
                        if (null === o) return e === this.device;
                        switch (o[0]) {
                            case "<":
                                return t.indexOf(this.device) < r;
                            case "<=":
                                return t.indexOf(this.device) <= r;
                            case ">":
                                return t.indexOf(this.device) > r;
                            case ">=":
                                return t.indexOf(this.device) >= r
                        }
                    }
                }, o(), window.addEventListener("resize", () => {
                    var e = window.viewport.device;
                    o(), window.viewport.device = t(), e !== window.viewport.device && (window.viewport.device, window.dispatchEvent(new Event("viewport-has-changed")))
                })
            })
        }, () => {
            window.addEventListener("DOMContentLoaded", () => {
                const e = document.querySelectorAll(".author-edito");
                e.length && e.forEach(o => {
                    const e = o.querySelectorAll(".author-box__tab"),
                        r = o.querySelectorAll(".author-box__pane"),
                        n = "_js-selected";
                    e.length && r.length && e.forEach(e => {
                        e.addEventListener("click", t => {
                            o.querySelector("." + n).classList.remove(n), t.currentTarget.classList.add(n), r.forEach(e => {
                                e.dataset.pane === t.currentTarget.dataset.tab ? e.classList.add(n) : e.classList.remove(n)
                            })
                        })
                    })
                })
            })
        }, () => {
            document.addEventListener("DOMContentLoaded", () => {
                const e = document.querySelectorAll(".category__title");
                if ("IntersectionObserver" in window) {
                    const t = new IntersectionObserver((e, t) => {
                        e.forEach(e => {
                            .5 < e.intersectionRatio && (e.target.classList.add("_js-visible"), t.unobserve(e.target))
                        })
                    }, {
                        root: null,
                        rootMargin: "0px",
                        threshold: .5
                    });
                    e.forEach(e => {
                        t.observe(e)
                    })
                } else e.forEach(e => {
                    e.classList.add("_js-visible")
                })
            })
        }, () => {
            document.addEventListener("DOMContentLoaded", () => {
                const e = document.querySelector("#comments-toggle"),
                    t = document.querySelector("#comments-body");
                e && t && e.addEventListener("click", e => {
                    e.preventDefault(), e.currentTarget.classList.toggle("_js-toggled"), t.classList.toggle("_js-open")
                })
            })
        }, () => {
            document.addEventListener("DOMContentLoaded", () => {
                const r = document.querySelector("body"),
                    e = document.querySelector("#post-content");
                if (e) {
                    const n = document.querySelector("#figure-fullscreen"),
                        a = () => {
                            n.classList.remove("_js-display"), r.classList.remove("_js-no-scroll")
                        };
                        
                    e.addEventListener("click", e => {
                        const t = e.target;
                        if ("IMG" === t.tagName && t.hasAttribute("data-fullscreensrc")) {
                            const o = n.querySelector(".figure-fullscreen__image");
                            n.classList.add("_js-display"), r.classList.add("_js-no-scroll"), o.setAttribute("src", t.dataset.fullscreensrc), window.addEventListener("keydown", e => {
                                "Escape" === e.key && a()
                            })
                            const preventClose = n.querySelector(".figure-fullscreen__image").addEventListener("click", (e) => {
                                e.preventDefault();
                                e.stopImmediatePropagation();
                            })
                            const clickToClose = n.querySelector(".figure-fullscreen__close").addEventListener("click", () => {
                                a()
                            })
                            
                            const clickToCloseWrapper = n.addEventListener("click", (evt) => {
                                if (evt.target.classList.contains('figure-fullscreen__navigation')) return;                            
                                a()
                            })

                            const clickNextImg = n.querySelector('.figure-fullscreen__navigation--next').addEventListener('click', () => {
                                window.dispatchEvent(new KeyboardEvent('keydown', {
                                    key: 'ArrowRight'
                                }));
                            })

                            const clickPrevImg = n.querySelector('.figure-fullscreen__navigation--prev').addEventListener('click', () => {
                                window.dispatchEvent(new KeyboardEvent('keydown', {
                                    key: 'ArrowLeft'
                                }));
                            })

                            const navigateImg = window.addEventListener("keydown", e => {
                                if ("ArrowRight" === e.key) {
                                    splide_list = t.closest('.splide__list');
                                    img = splide_list.querySelector('.splide__slide.is-next .figure-fullscreen-target')?.dataset.fullscreensrc || splide_list.querySelector('.splide__slide:first-child .figure-fullscreen-target')?.dataset.fullscreensrc
                                    console.log('click next', img);
                                    o.setAttribute("src", img)
                                }

                                if ("ArrowLeft" === e.key) {
                                    splide_list = t.closest('.splide__list');
                                    img = splide_list.querySelector('.splide__slide.is-prev .figure-fullscreen-target')?.dataset.fullscreensrc || splide_list.querySelector('.splide__slide:last-child .figure-fullscreen-target')?.dataset.fullscreensrc
                                    console.log('click prev', img);
                                    o.setAttribute("src", img)
                                }
                            })
                        }
                    })
                }
            })
        }, () => {
            document.addEventListener("DOMContentLoaded", () => {
                const e = document.querySelectorAll(".form"),
                    a = document.querySelector("#form-stripe"),
                    t = document.querySelector("body"),
                    s = e => {
                        e ? t.classList.remove("__js-no-scroll") : t.classList.add("__js-no-scroll")
                    },
                    d = document.querySelector("#form-confirm"),
                    c = e => {
                        e ? d.classList.add("__js-open") : d.classList.remove("__js-open")
                    },
                    o = document.querySelectorAll(".form__file"),
                    r = (o.length && o.forEach(n => {
                        n.querySelector(".form__file__field").addEventListener("change", e => {
                            var t = n.querySelector(".form__file__field").files;
                            const o = n.querySelector(".form__file__list");
                            n.querySelector(".form__label").innerHTML = "", o.innerHTML = "";
                            for (let e = 0; e < t.length; e++) {
                                const r = document.createElement("li");
                                r.textContent = t[e].name, o.appendChild(r)
                            }
                        })
                    }), document.querySelector("#form-password"));
                if (r) {
                    const g = r.querySelector(".form__text__field"),
                        h = document.querySelector("#form-password-levels");
                    var n = e => {
                        {
                            var t = h;
                            const o = /^__js-level-/;
                            t.classList.forEach(e => {
                                o.test(e) && t.classList.remove(e)
                            })
                        }
                        e = e.currentTarget.value;
                        if ("" !== e) switch (!0) {
                            case /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).+$/.test(e):
                                h.classList.add("__js-level-4");
                                break;
                            case /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).+$/.test(e):
                                h.classList.add("__js-level-3");
                                break;
                            case /^((?=.*[a-z])(?=.*[A-Z])|(?=.*[a-z])(?=.*[0-9])|(?=.*[a-z])(?=.*[!@#$%^&*])).+$/.test(e):
                                h.classList.add("__js-level-2");
                                break;
                            case /^(?=.*[a-z]).+$|^(?=.*[0-9]).+$/.test(e):
                                h.classList.add("__js-level-1")
                        }
                    };
                    let t = !0;
                    document.querySelector("#form-password-reveal").addEventListener("click", e => {
                        t ? (t = !1, e.currentTarget.classList.add("__js-active"), g.setAttribute("type", "text")) : (t = !0, e.currentTarget.classList.remove("__js-active"), g.setAttribute("type", "password"))
                    }), g && h && (g.addEventListener("keyup", n), g.addEventListener("change", n))
                }
                if (document.querySelector("#form-tel")) {
                    const w = document.querySelector("#form-tel-prefix-selected"),
                        L = document.querySelector("#form-tel-prefix-list"),
                        b = document.querySelector(".form__tel__field"),
                        S = (w.addEventListener("click", () => {
                            L.classList.add("__js-displayed")
                        }), (e, t) => {
                            w.classList.remove("lu", "de", "be", "fr"), w.classList.add(e), w.dataset.prefix = t
                        }),
                        q = (e, t) => {
                            var o = /^\+[0-9]{1,3}\s{0,}|^0{2}[0-9]{1,3}\s{0,}/;
                            const r = t.value;
                            "" !== r ? r.match(o) ? t.value = r.replace(o, e + " ") : t.value = "" + document.querySelector("#form-tel-prefix-selected").dataset.prefix + r : t.value = e + " "
                        };
                    var l = b;
                    switch (!0) {
                        case /^0{2}352/.test(l.value):
                            q("+352", l), S("lu", "+352");
                            break;
                        case /^0{2}49/.test(l.value):
                            q("+49", l), S("de", "+49");
                            break;
                        case /^0{2}33/.test(l.value):
                            q("+33", l), S("fr", "+33");
                            break;
                        case /^0{2}32/.test(l.value):
                            q("+32", l), S("be", "+32")
                    }
                    document.querySelectorAll(".form__tel__prefix-item").forEach(e => {
                        e.addEventListener("click", e => {
                            S(e.currentTarget.dataset.country, e.currentTarget.dataset.prefix), q(e.currentTarget.dataset.prefix, b), L.classList.remove("__js-displayed")
                        })
                    }), b.addEventListener("change", e => {
                        q(document.querySelector("#form-tel-prefix-selected").dataset.prefix, e.currentTarget)
                    })
                }
                const i = (e, t) => {
                        const o = document.createElement("span"),
                            r = (o.textContent = t, e.querySelector(".form__error"));
                        r && (r.style.marginTop = "5px", r.innerHTML = "", r.appendChild(o))
                    },
                    u = e => {
                        e.querySelector(".form__error").innerHTML = ""
                    },
                    m = e => "" === e.querySelector(".form__text__field").value,
                    _ = e => {
                        var t = e.querySelector(".form__file__field").files;
                        if (!t.length) return !1;
                        {
                            const o = e.dataset.format.split(",");
                            if (!o.length) return !0;
                            {
                                let e = 0;
                                if (e < t.length) return !!o.includes(t[e].type)
                            }
                        }
                    },
                    p = (e, t) => {
                        let o;
                        switch (t) {
                            case "radio":
                                o = e.querySelectorAll(".form__radios__field");
                                break;
                            case "checkbox":
                                o = e.querySelectorAll(".form__checkboxes__field")
                        }
                        if (o.length) {
                            let t = !0;
                            return o.forEach(e => {
                                !0 === e.checked && (t = !1)
                            }), t
                        }
                    };
                document.addEventListener("click", e => {
                    "form-confirm-yes" === e.target.id && (c(!1), s(!0), form.submit()), "form-confirm-no" === e.target.id && (c(!1), s(!0)), "form-confirm-close" === e.target.id && (c(!1), s(!0))
                });
                let f = null,
                    v = null;
                if (a) {
                    n = document.querySelector("#card-element");
                    const E = stripe.elements(),
                        x = E.create("card", {
                            hidePostalCode: !0
                        });
                    x.mount(n), f = stripe, v = x
                }
                const y = (e, t) => {
                    let o = !0;
                    return e.forEach(e => {
                        switch (e.dataset.type) {
                            case "text":
                                m(e) ? (o = !1, i(e, t.mandatoryField)) : u(e);
                                break;
                            case "textarea":
                                "" === e.querySelector(".form__textarea__field").value ? (o = !1, i(e, t.mandatoryField)) : u(e);
                                break;
                            case "number":
                                m(e) ? (o = !1, i(e, t.mandatoryField)) : (u(e), isNaN(parseInt(e.querySelector(".form__number__field").value)) ? (o = !1, i(e, t.invalidNumberFormat)) : u(e));
                                break;
                            case "email":
                                m(e) ? (o = !1, i(e, t.mandatoryField)) : /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(e.querySelector(".form__text__field").value.toLowerCase()) ? void 0 !== window.checkAccount && !window.checkAccount || u(e) : (u(e), o = !1, i(e, t.invalidEmailFormat));
                                break;
                            case "file":
                                _(e) ? u(e) : (o = !1, i(e, t.mandatoryField));
                                break;
                            case "password":
                                m(e) ? (o = !1, i(e, t.mandatoryField)) : 8 <= e.querySelector(".form__text__field").value.length ? u(e) : (o = !1, i(e, t.invalidPasswordLength));
                                break;
                            case "radio":
                                p(e, "radio") ? (o = !1, i(e, t.mandatoryField)) : u(e);
                                break;
                            case "checkbox":
                                p(e, "checkbox") ? (o = !1, i(e, t.mandatoryField)) : u(e);
                                break;
                            case "optin":
                                !1 === e.querySelector(".form__optin__field").checked ? (o = !1, i(e, t.mandatoryField)) : u(e);
                                break;
                            case "select":
                                "" === e.querySelector(".form__select__field").value ? (o = !1, i(e, t.mandatoryField)) : u(e)
                        }
                    }), o = void 0 === window.checkAccount || window.checkAccount ? o : !1
                };
                e.length && e.forEach(r => {
                    const n = r.querySelectorAll(".form__step");
                    n.length && n.forEach(o => {
                        const e = o.querySelector(".form__actions__step");
                        e && e.addEventListener("click", e => {
                            const t = e.currentTarget;
                            y(o.querySelectorAll(".form__control"), tb_scripts_params) && n.forEach(e => {
                                y(e.querySelectorAll(".form__controls")) && (e.dataset.step === t.dataset.nextstep ? e.classList.add("__js-displayed") : e.classList.remove("__js-displayed"))
                            })
                        })
                    }), r.addEventListener("submit", e => {
                        e.preventDefault();
                        const t = r.querySelector(".form__actions__submit");
                        t && t.setAttribute("disabled", "disabled");
                        var o, e = r.querySelectorAll(".form__control");
                        e.length && (y(e, tb_scripts_params) ? a ? (e = f, o = v, e.createToken(o).then(function(e) {
                            if (e.error) {
                                const t = document.querySelector("#card-errors");
                                t.innerHTML = "", (errorMessage = document.createElement("span")).textContent = e.error.message, t.appendChild(errorMessage), form_submit.removeAttribute("disabled")
                            } else {
                                const o = document.createElement("input");
                                o.setAttribute("type", "hidden"), o.setAttribute("name", "stripeToken"), o.setAttribute("value", e.token.id), form.appendChild(o), d ? (c(!0), s(!1)) : form.submit()
                            }
                        })) : d ? (c(!0), s(!1)) : r.submit() : t.removeAttribute("disabled"))
                    })
                })
            })
        }, () => {
            document.addEventListener("DOMContentLoaded", () => {
                const d = document.querySelector("body"),
                    e = () => {
                        if (window.viewport.is("<md")) {
                            const t = document.querySelector("#toggle-button");
                            if (t) {
                                window.navIsOpen = !1;
                                const o = document.querySelector("#header-nav"),
                                    r = document.querySelector("#header-nav-track"),
                                    n = () => {
                                        window.navIsOpen = !1, t.classList.remove("_js-toggled"), o.classList.remove("_js-open"), d.classList.remove("_js-no-scroll"), document.querySelectorAll(".main-nav__level").forEach(e => e.classList.remove("_js-display")), r.style.transform = "none"
                                    },
                                    e = (t.addEventListener("click", e => {
                                        e.stopImmediatePropagation(), window.navIsOpen ? n() : (window.navIsOpen = !0, t.classList.add("_js-toggled"), o.classList.toggle("_js-open"), d.classList.toggle("_js-no-scroll"))
                                    }), document.querySelectorAll(".menu-link")),
                                    a = (e.length && e.forEach(e => {
                                        e.addEventListener("click", () => {
                                            n()
                                        })
                                    }), document.querySelectorAll(".menu-level-child")),
                                    s = (a.length && a.forEach(e => {
                                        e.addEventListener("click", e => {
                                            e.currentTarget.nextElementSibling.classList.add("_js-display"), r.style.transform = `translateX(-${100*e.currentTarget.dataset.level}vw)`
                                        })
                                    }), document.querySelectorAll(".menu-level-parent"));
                                s.length && s.forEach(e => {
                                    e.addEventListener("click", e => {
                                        e.currentTarget.parentElement.classList.remove("_js-display"), r.style.transform = `translateX(${100*e.currentTarget.dataset.level}vw)`
                                    })
                                })
                            }
                        }
                    },
                    t = (e(), window.addEventListener("viewport-has-changed", () => {
                        e()
                    }), document.querySelector("#popup-newsletter")),
                    o = document.querySelectorAll(".widget__newsletter-cta__link");
                if (t && o.length) {
                    o.forEach(e => {
                        e.addEventListener("click", e => {
                            e.preventDefault(), t.classList.add("_js-open"), d.classList.add("_js-no-scroll")
                        })
                    });
                    const r = document.querySelector("#popup-newsletter-close");
                    r && r.addEventListener("click", () => {
                        t.classList.remove("_js-open"), d.classList.remove("_js-no-scroll")
                    })
                }
            })
        }, () => {
            window.addEventListener("DOMContentLoaded", () => {
                const e = () => {
                    const e = document.querySelectorAll(".gallery");
                    if (e.length) {
                        const n = {
                                type: "fade",
                                autoplay: !0,
                                interval: 5e3,
                                pauseOnHover: !0,
                                lazyLoad: "nearby",
                                rewind: !0,
                                pagination: !1,
                                arrows: !1
                            },
                            a = {
                                fixedWidth: 120,
                                fixedHeight: 80,
                                //cover: !0,
                                //focus: "center",
                                gap: 10,
                                rewind: !0,
                                pagination: !1,
                                isNavigation: !0
                            };
                        e.forEach(e => {
                            e.style.opacity = 0;
                            var t = e.querySelector(".gallery-main");
                            const o = new Splide(t, n);
                            t = e.querySelector(".gallery-thumbnails");
                            const r = new Splide(t, a);

                            o.on('mounted', function() {
                                setTimeout(function() {
                                    e.style.opacity = 1;
                                }, 1000);
                                console.log(`${t} splide mounted`);
                            })
                            
                            o.sync(r), o.mount(), r.mount()
                        })
                    }
                };
                e()
            })
        }, () => {
            document.addEventListener("DOMContentLoaded", () => {
                if (document.querySelector(".home")) {
                    const o = (t, e, o, r) => {
                            if ("1" === homepage.headlines_loaded) {
                                const n = new Headers,
                                    a = (n.append("Content-Type", "application/json"), new FormData);
                                a.append('postID', tb_scripts_params.current_post);
                                a.append("action", e), a.append("_ajax_nonce", tb_scripts_params.ajax_nonce), a.append("category", o), a.append("posts_per_page", r), a.append("posts_not_in", homepage.posts_not_in), fetch(tb_scripts_params.ajax_url, {
                                    body: a,
                                    method: "post"
                                }).then(e => e.json()).then(e => {
                                    if(e.html == ''){
                                        if(t.classList.contains('ajax_category')){
                                            let allParents = Array.from(parents(t))
                                            allParents.forEach(myParent => {
                                                if(myParent.classList != undefined){
                                                    if(myParent.classList.contains("layout__block")){
                                                        myParent.remove();
                                                    }
                                                }

                                            })
                                        }else{
                                            t.parentElement.parentElement.remove()
                                        }

                                        function parents(node) {
                                            var nodes = [node]
                                            for (; node; node = node.parentNode) {
                                              nodes.unshift(node)
                                            }
                                            return nodes
                                          }
                                    }else{
                                        homepage.posts_not_in = e.posts_not_in, t.innerHTML = e.html
                                    }
                                }).catch(e => console.error(e))
                            }
                        },
                        r = window.innerHeight + window.innerHeight / 2,
                        e = document.querySelector(".ajax_edito"),
                        t = (window.edito_loaded = !1, window.addEventListener("scroll", () => {
                            window.edito_loaded || (!!e && e.getBoundingClientRect().y <= r) && (o(e, "tb_ajax_get_editos", "editorial", 2), window.edito_loaded = !0)
                        }), document.querySelector(".ajax_thematic")),
                        n = (t && (window.thematic_loaded = !1, window.addEventListener("scroll", () => {
                            window.thematic_loaded || t.getBoundingClientRect().y <= r && (o(t, "tb_ajax_get_thematic", null, 5), window.thematic_loaded = !0)
                        })), document.querySelectorAll(".ajax_category")),
                        a = (n.forEach(e => {
                            const t = e.dataset.category + "_loaded";
                            window[t] = !1, window.addEventListener("scroll", () => {
                                window[t] || e.getBoundingClientRect().y <= r && (o(e, "tb_ajax_get_posts", e.dataset.category, 7), window[t] = !0)
                            })
                        }), document.querySelector(".ajax_most_read_posts")),
                        s = (a && (window.most_read_loaded = !1, window.addEventListener("scroll", () => {
                            window.most_read_loaded || a.getBoundingClientRect().y <= r && (o(a, "tb_ajax_get_most_read", null, 5), window.most_read_loaded = !0)
                        })), document.querySelector(".ajax_most_commented_posts"));
                    s && (window.most_commented_loaded = !1, window.addEventListener("scroll", () => {
                        window.most_commented_loaded || s.getBoundingClientRect().y <= r && (o(s, "tb_ajax_get_most_commented", null, 5), window.most_commented_loaded = !0)
                    }))
                }else if (document.querySelector(".single-post") || document.querySelector(".archive") || document.querySelector('.page-template-default')){
                    const o = (t, e, o, r) => {
                        if ("1" === homepage.headlines_loaded) {
                            const n = new Headers,
                                a = (n.append("Content-Type", "application/json"), new FormData);
                            a.append("action", e), a.append("_ajax_nonce", tb_scripts_params.ajax_nonce), a.append("category", o), a.append("posts_per_page", r), a.append("posts_not_in", homepage.posts_not_in), fetch(tb_scripts_params.ajax_url, {
                                body: a,
                                method: "post"
                            }).then(e => e.json()).then(e => {
                                if(e.html == ''){
                                    if(t.classList.contains('ajax_category')){
                                        let allParents = Array.from(parents(t))
                                        allParents.forEach(myParent => {
                                            if(myParent.classList != undefined){
                                                if(myParent.classList.contains("layout__block")){
                                                    myParent.remove();
                                                }
                                            }

                                        })
                                    }else{
                                        t.parentElement.parentElement.remove()
                                    }
                                }else{
                                    homepage.posts_not_in = e.posts_not_in, t.innerHTML = e.html
                                }                            
                            }).catch(e => console.error(e))
                        }
                    },
                    r = window.innerHeight + window.innerHeight / 2,
                    a = document.querySelector(".ajax_most_read_posts"),
                    s = (a && (window.most_read_loaded = !1, window.addEventListener("scroll", () => {
                        window.most_read_loaded || a.getBoundingClientRect().y <= r && (o(a, "tb_ajax_get_most_read", null, 5), window.most_read_loaded = !0)
                    })), document.querySelector(".ajax_most_commented_posts"));
                    s && (window.most_commented_loaded = !1, window.addEventListener("scroll", () => {
                        window.most_commented_loaded || s.getBoundingClientRect().y <= r && (o(s, "tb_ajax_get_most_commented", null, 5), window.most_commented_loaded = !0)
                    })),
                    a = document.querySelectorAll(".ajax_category").forEach(e => {
                        const t = e.dataset.category + "_loaded";
                        window[t] = !1, window.addEventListener("scroll", () => {
                            window[t] || e.getBoundingClientRect().y <= r && (o(e, "tb_ajax_get_posts", e.dataset.category, 7), window[t] = !0)
                        })
                    })
                }
            })
        }, () => {
            document.addEventListener("DOMContentLoaded", () => {
                const t = document.querySelector("#search-form"),
                    l = document.querySelector("#search-result");
                if (l && t) {
                    const i = document.querySelector("#loader"),
                        u = document.querySelector("#search-message"),
                        m = document.querySelector("#search-count"),
                        o = document.querySelector("#search-term"),
                        r = document.querySelector("#search-category"),
                        n = document.querySelector("#search-date"),
                        _ = document.querySelector("#search-pagination"),
                        p = document.querySelector("#search-links"),
                        a = c => {
                            m.style.display = "none", l.innerHTML = "", p.innerHTML = "", u.style.display = "none", i.style.display = "flex";
                            const e = new Headers,
                                t = (e.append("Content-Type", "application/json"), new FormData);
                            t.append("action", "tb_ajax_get_search"), t.append("_ajax_nonce", tb_scripts_params.ajax_nonce), t.append("search_term", o.value), t.append("search_page", c), "all" !== r.value && t.append("search_category", r.value), "all" !== n.value && t.append("search_date", n.value), fetch(tb_scripts_params.ajax_url, {
                                body: t,
                                method: "post"
                            }).then(e => e.json()).then(t => {
                                if (i.style.display = "none", 0 < t.found_posts) {
                                    if (m.style.display = "block", m.querySelector("span").textContent = t.found_posts, l.innerHTML = t.html, 1 < t.max_num_pages) {
                                        if (_.style.display = "block", 1 < c) {
                                            const r = document.createElement("a");
                                            r.classList.add("page-numbers", "prev"), r.dataset.page = c - 1, p.appendChild(r)
                                        }
                                        if (2 <= c)
                                            for (let e = c - 2; e < c; e++)
                                                if (0 < e) {
                                                    const n = document.createElement("a");
                                                    n.classList.add("page-numbers"), n.dataset.page = e, n.textContent = e, p.appendChild(n)
                                                } const e = document.createElement("span");
                                        if (e.classList.add("page-numbers", "current"), e.textContent = c, p.appendChild(e), c < t.max_num_pages - 1)
                                            for (let e = c + 1; e <= c + 2; e++)
                                                if (e < t.max_num_pages) {
                                                    const a = document.createElement("a");
                                                    a.classList.add("page-numbers"), a.dataset.page = e, a.textContent = e, p.appendChild(a)
                                                } if (c < t.max_num_pages - 2 - 1) {
                                            const s = document.createElement("span");
                                            s.classList.add("page-numbers", "dots"), s.textContent = "...", p.appendChild(s)
                                        }
                                        const o = document.createElement("a");
                                        if (o.classList.add("page-numbers"), o.dataset.page = t.max_num_pages, o.textContent = t.max_num_pages, p.appendChild(o), c < t.max_num_pages) {
                                            const d = document.createElement("a");
                                            d.classList.add("page-numbers", "next"), d.dataset.page = c + 1, p.appendChild(d)
                                        }
                                    }
                                } else u.style.display = "block", u.textContent = "Es tut uns leid, aber wir haben keine Ergebnisse gefunden..."
                            }).catch(e => console.error(e))
                        };
                    t.addEventListener("submit", e => {
                        e.preventDefault(), m.style.display = "none", "" === o.value ? (u.style.display = "block", u.textContent = "Sie haben keinen Suchbegriff eingegeben...") : a(1)
                    }), p.addEventListener("click", e => {
                        e.target.dataset.page && (window.scroll({
                            top: t.offsetTop - document.querySelector(".header").offsetHeight,
                            left: 0,
                            bahavior: "smooth"
                        }), a(parseInt(e.target.dataset.page)))
                    })
                }
            })
        }, () => {
            document.addEventListener("DOMContentLoaded", () => {
                var e = document.querySelector("#single");
                if (e && window.viewport.is(">xs")) {
                    const r = document.querySelector("#share-container"),
                        n = document.querySelector("#share"),
                        a = document.querySelector(".header__bottom"),
                        s = document.querySelector("#post-content");
                    window.addEventListener("scroll", () => {
                        // var e = window.scrollY,
                        //     t = r.offsetTop - a.offsetHeight,
                        //     o = t + (s.offsetHeight - n.offsetHeight);
                        // e <= t && (n.style.top = "0px"), t < e && e < o && (n.style.top = e + a.offsetHeight - r.offsetTop + 10 + "px")
                    })
                }
            })
        }],
        r = {};

    function n(e) {
        var t = r[e];
        if (void 0 !== t) return t.exports;
        t = r[e] = {
            exports: {}
        };
        return o[e](t, t.exports, n), t.exports
    }
    n.n = e => {
        var t = e && e.__esModule ? () => e.default : () => e;
        return n.d(t, {
            a: t
        }), t
    }, n.d = (e, t) => {
        for (var o in t) n.o(t, o) && !n.o(e, o) && Object.defineProperty(e, o, {
            enumerable: !0,
            get: t[o]
        })
    }, n.o = (e, t) => Object.prototype.hasOwnProperty.call(e, t), n.r = e => {
        "undefined" != typeof Symbol && Symbol.toStringTag && Object.defineProperty(e, Symbol.toStringTag, {
            value: "Module"
        }), Object.defineProperty(e, "__esModule", {
            value: !0
        })
    };
    n(2), n(3), n(4), n(5), n(6), n(7), n(8), n(9), n(10), n(11), n(12), n(13), n(14), n(1)
})();